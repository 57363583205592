var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-1" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        _vm._l(_vm.answers, function(answer, ansindex) {
          return _c(
            "v-chip",
            {
              key: ansindex,
              staticClass:
                "font-weight-medium subtitle-2 disable-events mr-1 my-1",
              attrs: {
                label: "",
                small: "",
                color: "primary",
                text: !_vm.correct[ansindex],
                outlined: !_vm.correct[ansindex]
              }
            },
            [
              _vm._v(" " + _vm._s(answer) + " "),
              _vm.correct[ansindex]
                ? _c("font-awesome-icon", {
                    attrs: { icon: "check", pull: "right" }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }