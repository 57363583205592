<template>
  <v-row v-if="questions" class="sticky mb-4 accent3 secondary--text" no-gutters>
    <v-col cols="3" class="pa-sm-4 d-flex flex-column align-center" v-for="(stat, key) in stats" :key="stat.key">
      <v-avatar color="accent2" :size="size"
        ><span class="subtitle-1 font-weight-bold">{{ stat }}</span>
      </v-avatar>
      <span class="text-center subtitle-2 font-weight-bold">{{ key }}</span>
    </v-col>
    <v-col cols="3" class="pa-sm-4 d-flex flex-column align-center">
      <v-avatar v-show="!compact" color="accent2" :size="size">
        <v-btn icon color="secondary" @click="docompact">
          <font-awesome-icon icon="compress" class="ma-1"></font-awesome-icon>
        </v-btn>
      </v-avatar>
      <span class="text-center font-weight-bold subtitle-2" v-if="!compact">collapse</span>
      <v-avatar v-show="compact" color="accent2" :size="size">
        <v-btn icon color="secondary" @click="docompact">
          <font-awesome-icon icon="expand" class="ma-1"></font-awesome-icon>
        </v-btn>
      </v-avatar>
      <span class="text-center font-weight-bold subtitle-2" v-if="compact">expand</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuizStatsCircle",
  props: {
    compact: {
      type: Boolean,
      default: () => true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    size() {
      return this.$vuetify.breakpoint.xl ? 56 : 48
    },

    stats() {
      return { time: this.totalQuizTime, points: this.totalQuizPoints, questions: this.questions.length }
    },

    totalQuestions() {
      return this.questions.length
    },

    totalQuizTime() {
      return (
        Math.ceil(
          (this.questions?.length ? this.questions.map((e) => e.timer).reduce((total, a) => total + a) : 0) / 60
        ) + "m"
      )
    },

    totalQuizPoints() {
      return this.questions?.length ? this.questions.map((e) => e.points).reduce((total, a) => total + a) : 0
    },
  },
  methods: {
    docompact() {
      this.$emit("docompact")
    },
  },
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}
</style>
