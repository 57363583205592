<template>
  <v-col cols="12" class="background pa-4 rounded-lg">
    <v-row align="center" justify="center" no-gutters>
      <v-btn
        text
        block
        color="success"
        @click="selectFile"
        v-if="!currentquestion.imagelink & !uploadEnd && !uploading"
      >
        <font-awesome-icon icon="upload" pull="left"></font-awesome-icon>
        Upload Image</v-btn
      >
      <v-btn text block color="error" @click="deleteImage()" v-if="uploadEnd || currentquestion.imagelink">
        <font-awesome-icon icon="times" pull="left"></font-awesome-icon>
        Delete Image</v-btn
      >
    </v-row>
    <v-row align="center" justify="center" no-gutters class="pb-xl-4">
      <v-col class="text-center">
        <v-img
          class="attachment"
          :src="currentquestion.imagelink"
          height="150"
          contain
          v-if="uploadEnd || currentquestion.imagelink"
        />
        <v-progress-circular
          v-if="uploading && !uploadEnd"
          :size="100"
          :width="10"
          :rotate="360"
          :value="progressUpload"
          color="warning"
        >
          {{ progressUpload }} %
        </v-progress-circular>
        <input
          id="files"
          type="file"
          name="file"
          ref="uploadInput"
          accept="image/*"
          :multiple="false"
          @change="detectFiles($event)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { firebaseApp } from "@/firebase"
import { mapState, mapActions } from "vuex"
import { getStorage, getDownloadURL, ref, uploadBytesResumable, updateMetadata, deleteObject } from "firebase/storage"
const storage = getStorage(firebaseApp)

export default {
  name: "QuestionAttachment",
  data() {
    return {
      progressUpload: 0,
      uploadTask: "",
      downloadURL: "",
      uploading: false,
      uploadEnd: false,
      filemetadata: {},
      storageRef: null,
    }
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
        },
        null,
        () => {
          getDownloadURL(this.uploadTask.snapshot.ref).then((downloadURL) => {
            updateMetadata(this.storageRef, this.filemetadata)
            this.updateCurrentQuestion({ imagelink: downloadURL })
            this.resetUpload()
          })
        }
      )
    },
  },
  mounted() {
    this.$eventBus.$on("deleteImage", this.deleteImage)
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      quizid: (state) => state.quiz.currentQuizId,
      currentquestion: (state) => state.quiz.currentQuestion,
      currentQuestionId: (state) => state.quiz.currentQuestionId,
    }),
  },
  methods: {
    ...mapActions(["updateCurrentQuestion", "addOrUpdateQuestionInQuiz"]),

    selectFile() {
      this.$refs.uploadInput.click()
    },

    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x])
      })
    },

    upload(file) {
      this.updateCurrentQuestion({ fileName: file.name })
      this.filemetadata = {
        cacheControl: "public,max-age=604800",
        contentType: file.type,
      }
      this.uploading = true
      this.storageRef = ref(storage, `images/${this.user.uid}/${this.quizid}/${file.name}`)
      this.uploadTask = uploadBytesResumable(this.storageRef, file)
    },

    resetUpload() {
      this.$refs.uploadInput.value = null
      this.uploading = false
      this.uploadEnd = false
      this.downloadURL = ""
      this.filemetadata = {}
      this.storageRef = null
    },

    async deleteImage() {
      if (this.currentquestion.imagelink) {
        try {
          const fileref = ref(storage, `images/${this.user.uid}/${this.quizid}/` + this.currentquestion.fileName)
          await deleteObject(fileref)
          this.resetUpload()
          this.updateCurrentQuestion({ imagelink: "", fileName: "" })
          if (this.currentQuestionId) {
            this.addOrUpdateQuestionInQuiz()
          }
        } catch (error) {
          console.error(`error deleteing file ${error}`)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.attachment {
  position: relative;
}
.overlay {
  position: absolute;
  top: 60%;
  left: 75%;
}
</style>
