var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "newQuestionForm",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveQuestion()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column pa-4 rounded-lg questionform",
          class: !_vm.valid ? "invalid" : "",
          attrs: {
            outlined: _vm.$vuetify.breakpoint.mdAndUp,
            elevation: _vm.$vuetify.breakpoint.mdAndUp ? 0 : 0,
            "min-height": "80vh"
          }
        },
        [
          _c("v-card-title", { staticClass: "text-h5 font-weight-black" }, [
            _vm._v(
              _vm._s(
                _vm.currentQuestionId !== null
                  ? "Editing Question " + (_vm.currentQuestionId + 1)
                  : "New Question"
              )
            )
          ]),
          _c(
            "v-row",
            { attrs: { align: "start", justify: "space-between" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    order: _vm.$vuetify.breakpoint.smAndDown ? 1 : 0
                  }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-4 mb-2", attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-2 pa-0",
                        attrs: {
                          dense: "",
                          rows: "1",
                          label: "Question",
                          placeholder: "Enter your question here",
                          value: _vm.currentquestion.question,
                          rules: _vm.questionrule,
                          counter: "",
                          clearable: "",
                          maxlength: "150"
                        },
                        on: {
                          blur: function($event) {
                            return _vm.updateCurrentQuestion({
                              question: $event.target.value
                            })
                          }
                        }
                      }),
                      _vm.hintenabled && _vm.currentquestion.answertype !== "P"
                        ? _c("v-textarea", {
                            staticClass: "mx-0 mt-4 pa-0",
                            attrs: {
                              dense: "",
                              rows: "1",
                              label: "Hint",
                              placeholder:
                                "Participant can see this during gameplay optionally.",
                              value: _vm.currentquestion.hint,
                              rules: _vm.hintrule,
                              counter: "",
                              clearable: "",
                              maxlength: "150"
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateCurrentQuestion({
                                  hint: $event.target.value
                                })
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._l(_vm.currentquestion.answers, function(ans, ansindex) {
                    return _c(
                      "v-row",
                      {
                        key: ansindex,
                        staticClass: "px-2 py-1 mb-xl-2",
                        attrs: { "no-gutters": "" }
                      },
                      [
                        _vm.currentquestion.answertype !== "P"
                          ? _c("v-checkbox", {
                              attrs: {
                                dense: "",
                                "input-value":
                                  _vm.currentquestion.correctanswers[ansindex],
                                value: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleCorrectAnswer(
                                    _vm.currentquestion.correctanswers[
                                      ansindex
                                    ],
                                    ansindex
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            label: "Answer " + (Number(ansindex) + 1),
                            value: ans,
                            rules: _vm.answerrule
                          },
                          on: {
                            blur: function($event) {
                              return _vm.handleAnswerText(
                                $event.target.value,
                                ansindex
                              )
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.currentquestion.answertype !== "T"
                                ? {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        ansindex ===
                                          _vm.currentquestion.answers.length -
                                            1 && ansindex < 5
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "success",
                                                  text: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: { icon: "plus" },
                                                  on: { click: _vm.addOption }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        ansindex > 1
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "error",
                                                  text: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    color: "error",
                                                    icon: "minus"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeOption(
                                                        ansindex
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "background mb-1 rounded-lg",
                      attrs: {
                        "no-gutters": "",
                        align: "center",
                        justify: "center"
                      }
                    },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            value: _vm.currentquestion.answertype,
                            mandatory: "",
                            row: ""
                          },
                          on: {
                            blur: function($event) {
                              return _vm.updateCurrentQuestion({
                                question: $event.target.value
                              })
                            }
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              dense: "",
                              value: "T",
                              label: "True/False"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setOptions("T")
                              }
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              dense: "",
                              value: "M",
                              label: "Multi-Choice"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setOptions("M")
                              }
                            }
                          }),
                          _c("v-radio", {
                            attrs: { dense: "", value: "P", label: "Poll" },
                            on: {
                              click: function($event) {
                                return _vm.setOptions("P")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "background mb-1 rounded-lg",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-slider", {
                            staticClass: "px-4 py-2",
                            attrs: {
                              "hide-details": "",
                              label: "Points",
                              value: _vm.currentquestion.points,
                              step: "10",
                              min: "10",
                              max: "100",
                              "thumb-label": "always"
                            },
                            on: {
                              end: function($event) {
                                return _vm.updateCurrentQuestion({
                                  points: $event
                                })
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticClass: "background mb-1 rounded-lg",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-slider", {
                            staticClass: "px-4 py-2",
                            attrs: {
                              "hide-details": "",
                              label: "Time",
                              value: _vm.currentquestion.timer,
                              step: "5",
                              min: "15",
                              max: "90",
                              "thumb-label": "always"
                            },
                            on: {
                              end: function($event) {
                                return _vm.updateCurrentQuestion({
                                  timer: $event
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [_vm._t("questionattachment")],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("question-form-toolbar", {
            attrs: {
              questionid: _vm.currentQuestionId,
              questiontotal: _vm.totalquestions
            },
            on: {
              nextQuestion: _vm.next,
              previousQuestion: _vm.previous,
              addQuestion: _vm.setQuestionFromQuiz,
              resetQuestion: _vm.resetQuestion,
              openSettings: _vm.openSettings,
              deleteQuestion: _vm.deleteQuestion,
              saveQuestion: _vm.saveQuestion,
              saveAndAddNewQuestion: _vm.saveAndAddNewQuestion
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }