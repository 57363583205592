<template>
  <v-container fluid class="ma-0 pa-0" v-if="quiz">
    <QuizFormToolBar :editquiz="editquiz" @togglequizform="toggleQuizForm"></QuizFormToolBar>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="4"
        xl="3"
        class="question-list-column accent3 px-xl-2"
        v-show="$vuetify.breakpoint.mdAndUp && !editquiz"
      >
        <QuizStatsCircle :questions="quiz.questions" :compact="compact" @docompact="docompact"></QuizStatsCircle>

        <h3 class="subtitle-1 ma-4 text-center" v-if="questions && questions.length === 0">
          Questions you add will appear here. Add a minimum of 5 Questions to start the quiz and play
        </h3>
        <draggable v-model="questions" @start="drag = true" @end="drag = false">
          <transition-group
            name="flip-list"
            tag="div"
            enter-active-class="animate__animated animate__slideInLeft"
            leave-active-class="animate__animated animate__slideOutLeft"
          >
            <question-card
              v-for="(question, index) in questions"
              :key="question.question + index"
              :question="question"
              :qid="index"
              :active="currentQuestionId === index"
              :compact="compact"
            >
              <template v-slot:answerslot>
                <question-card-answers-list
                  :answers="question.answers"
                  :correct="question.correctanswers"
                ></question-card-answers-list>
              </template>
            </question-card>
          </transition-group>
        </draggable>
      </v-col>
      <v-col cols="12" :md="editquiz ? 12 : 8" :xl="editquiz ? 12 : 9" class="white main-column">
        <v-row class="pa-4 pa-lg-4">
          <v-col cols="12">
            <transition
              enter-active-class="animate__animated animate__slideInDown"
              leave-active-class="animate__animated animate__slideOutRight"
              mode="out-in"
            >
              <quiz-form
                :quiz="quiz"
                :quizid="quizid"
                @closeQuizForm="toggleQuizForm"
                :key="editquiz ? 'questionform' : 'quizform'"
                v-if="editquiz"
              ></quiz-form>
              <question-form
                @showQuestionForm="toggleQuizForm"
                @closeQuestionForm="toggleQuizForm"
                :key="editquiz ? 'questionform' : 'quizform'"
                v-if="!editquiz"
                :currentQuestionId="currentQuestionId"
                :totalquestions="questions.length"
              >
                <template v-slot:questionattachment>
                  <question-attachment></question-attachment>
                </template>
              </question-form>
            </transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading :loading="overlay" :text="quizLoadingText" />
  </v-container>
</template>

<script>
import draggable from "vuedraggable"
import { mapState, mapActions, mapMutations } from "vuex"
import QuestionCard from "@/components/quiz/QuestionCard"
import QuestionForm from "@/components/quiz/QuestionForm"
import QuestionCardAnswersList from "@/components/quiz/QuestionCardAnswersList"
import QuestionAttachment from "@/components/quiz/QuestionAttachment"
import QuizForm from "@/components/quiz/QuizForm"
import QuizStatsCircle from "@/components/quiz/QuizStatsCircle"
import QuizFormToolBar from "@/components/quiz/QuizFormToolbar"
import Loading from "@/components/common/Loading"
import { getQuiz } from "@/api/QuizApi"

export default {
  name: "EditQuiz",
  data() {
    return {
      editquiz: false,
      titlerule: [(v) => !!v || "title is required!"],
      descrule: [(v) => !!v || "description is required!"],
      timerrule: [(v) => !!v || "timer is required!"],
      compact: true,
      overlay: true,
      quizLoadingText: "Fecthing your quiz!",
    }
  },

  components: {
    QuizFormToolBar,
    QuizStatsCircle,
    QuestionCard,
    QuestionForm,
    QuestionCardAnswersList,
    QuestionAttachment,
    QuizForm,
    draggable,
    Loading,
  },

  async beforeCreate() {
    await getQuiz(this.$route.params.id)
    this.overlay = false
  },

  computed: {
    ...mapState({
      quiz: (state) => state.quiz.currentQuiz,
      quizid: (state) => state.quiz.currentQuizId,
      currentQuestionId: (state) => state.quiz.currentQuestionId,
      user: (state) => state.user.currentUser,
      quizlistener: (state) => state.quiz.quizListener,
    }),

    questions: {
      get() {
        return this.quiz.questions || []
      },
      set(value) {
        this.updateQuestionOrder(value)
      },
    },
  },

  destroyed() {
    getQuiz()
    this.quizlistener()
  },

  methods: {
    ...mapActions(["updateQuestionOrder"]),

    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
    }),

    toggleQuizForm() {
      this.editquiz = !this.editquiz
    },

    docompact() {
      this.compact = !this.compact
    },
  },
}
</script>
<style lang="scss" scoped>
.question-list-column {
  height: calc(100vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.main-column {
  overflow-y: hidden;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.4rem;
  background: var(--v-accent2-base);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--v-accent2-base);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-accent2-darken2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flip-list-move {
  transition: transform 0.8s ease;
}
</style>
