var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.mdAndUp && _vm.quiz && _vm.quizid
    ? _c(
        "v-toolbar",
        { staticClass: "white", attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "text-h5 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.quiz.title))
          ]),
          _c("v-spacer"),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { outlined: "", color: "accent1", to: { name: "Quizzes" } }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "dice-d20", pull: "left" }
              }),
              _vm._v(" Back to Quizzes")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                disabled: !_vm.user.email,
                outlined: "",
                color: "secondary",
                to: { name: "sessions", params: { id: _vm.quizid } }
              }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "file-contract", pull: "left" }
              }),
              _vm._v(" Reports")
            ],
            1
          ),
          !_vm.user.email
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary", to: "/guestsignup" }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "user-plus", pull: "left" }
                  }),
                  _vm._v(" Signup")
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { depressed: "", color: "accent", disabled: _vm.editquiz },
              on: { click: _vm.togglequizform }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "cog", pull: "left" } }),
              _vm._v(" Settings ")
            ],
            1
          ),
          _vm.user.email
            ? _c("SessionPopoverMenu", {
                attrs: {
                  quizid: _vm.quizid,
                  quiztype: _vm.quiz.type,
                  disabled: _vm.minquestions
                }
              })
            : _vm._e(),
          _c(
            "v-btn",
            { attrs: { color: "error", icon: "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "heart", pull: "left" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }