<template>
  <v-toolbar v-if="$vuetify.breakpoint.mdAndUp && quiz && quizid" flat class="white">
    <v-toolbar-title class="text-h5 font-weight-bold">{{ quiz.title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <v-btn class="mr-2" outlined color="accent1" :to="{ name: 'Quizzes' }">
      <font-awesome-icon icon="dice-d20" pull="left"></font-awesome-icon>
      Back to Quizzes</v-btn
    >
    <v-btn
      :disabled="!user.email"
      class="mr-2"
      outlined
      color="secondary"
      :to="{ name: 'sessions', params: { id: quizid } }"
    >
      <font-awesome-icon icon="file-contract" pull="left"></font-awesome-icon>
      Reports</v-btn
    >
    <v-btn class="mr-2" color="primary" v-if="!user.email" to="/guestsignup">
      <font-awesome-icon icon="user-plus" pull="left"></font-awesome-icon>
      Signup</v-btn
    >
    <v-btn depressed color="accent" class="mr-2" @click="togglequizform" :disabled="editquiz">
      <font-awesome-icon icon="cog" pull="left"></font-awesome-icon>
      Settings
    </v-btn>

    <SessionPopoverMenu
      v-if="user.email"
      :quizid="quizid"
      :quiztype="quiz.type"
      :disabled="minquestions"
    ></SessionPopoverMenu>
    <v-btn color="error" icon>
      <font-awesome-icon icon="heart" pull="left"></font-awesome-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapState } from "vuex"
import SessionPopoverMenu from "@/components/quiz/SessionPopoverMenu"
export default {
  name: "QuizFormToolBar",
  components: {
    SessionPopoverMenu,
  },
  props: {
    editquiz: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      started: false,
    }
  },
  computed: {
    ...mapState({
      quiz: (state) => state.quiz.currentQuiz,
      quizid: (state) => state.quiz.currentQuizId,
      user: (state) => state.user.currentUser,
    }),

    minquestions() {
      return this.quiz?.questions?.length <= 4
    },
  },
  methods: {
    deletequiz() {
      this.$eventBus.$emit("deletequiz", this.quizid)
    },
    togglequizform() {
      this.$emit("togglequizform")
    },
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  border-bottom: 2px solid var(--v-accent2-base) !important;
}
</style>
