<template>
  <v-container>
    <v-row v-if="$vuetify.breakpoint.xs" align="center" justify="space-between" no-gutters class="pt-2">
      <v-col cols="auto" v-if="!newquestion">
        <v-btn icon large color="success" @click.once="saveQ()">
          <font-awesome-icon icon="save" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="newquestion">
        <v-btn icon large color="success" @click="saveAndAddQ()">
          <font-awesome-icon icon="save" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="!newquestion">
        <v-btn icon large color="error" @click="deleteQ()">
          <font-awesome-icon icon="times-circle" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="newquestion">
        <v-btn icon large color="warning" @click="resetQ()">
          <font-awesome-icon icon="undo" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn icon color="secondary" @click="previousQ()" :disabled="firstquestion">
          <font-awesome-icon icon="angle-double-left" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn icon large color="secondary" @click="next()" :disabled="newquestion || lastquestion">
          <font-awesome-icon icon="angle-double-right" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn icon large color="accent" @click="addQ()" v-if="!newquestion">
          <font-awesome-icon icon="plus" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn icon large color="accent" @click="settings()">
          <font-awesome-icon icon="cog" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.sm" justify="space-between">
      <v-col cols="auto">
        <v-btn class="mr-2" large color="success" @click="saveQ()" v-if="!newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Update</v-btn
        >
        <v-btn class="mr-2" large color="success" @click="saveAndAddQ()" v-if="newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>

          Save & Add</v-btn
        >
        <v-btn class="mr-2" large color="error" v-if="!newquestion" @click="deleteQ()">
          <font-awesome-icon icon="times-circle" pull="left"></font-awesome-icon>

          Delete</v-btn
        >
        <v-btn class="mr-2" large color="warning" v-if="newquestion" @click="resetQ()">
          <font-awesome-icon icon="undo" pull="left"></font-awesome-icon>

          Reset</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn class="mr-2" icon large color="secondary" @click="previousQ()" :disabled="firstquestion">
          <font-awesome-icon icon="angle-double-left" size="lg"></font-awesome-icon>
        </v-btn>

        <v-btn class="mr-2" icon large color="secondary" @click="next()" :disabled="newquestion || lastquestion">
          <font-awesome-icon icon="angle-double-right" size="lg"></font-awesome-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn large color="accent" class="mr-2" @click="addQ()" v-if="!newquestion">
          <font-awesome-icon icon="plus" pull="left"></font-awesome-icon>

          Add New
        </v-btn>
        <v-btn large color="accent" class="mr-2" @click="settings()">
          <font-awesome-icon icon="cog" pull="left"></font-awesome-icon>
          Settings
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.md" justify="space-between">
      <v-col cols="auto">
        <v-btn class="mr-2" color="success" @click="saveQ()" v-if="!newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Update</v-btn
        >
        <v-btn class="mr-2" color="success" @click="saveAndAddQ()" v-if="newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Save & Add</v-btn
        >
        <v-btn class="mr-2" color="error" v-if="!newquestion" @click="deleteQ()">
          <font-awesome-icon icon="times-circle" pull="left"></font-awesome-icon>
          Delete</v-btn
        >
        <v-btn class="mr-2" color="warning" v-if="newquestion" @click="resetQ()">
          <font-awesome-icon icon="undo" pull="left"></font-awesome-icon>
          Reset</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn class="mr-2" color="secondary" @click="previousQ()" :disabled="firstquestion">
          <font-awesome-icon icon="angle-double-left" pull="left"></font-awesome-icon>
          Prev</v-btn
        >
        <v-btn class="mr-2" color="secondary" @click="next()" :disabled="newquestion || lastquestion">
          <font-awesome-icon icon="angle-double-right" pull="left"></font-awesome-icon>
          Next</v-btn
        >
      </v-col>

      <v-col cols="auto">
        <v-btn color="accent" @click="addQ()" :disabled="newquestion">
          <font-awesome-icon icon="plus" pull="left"></font-awesome-icon>
          Add New
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.lgAndUp" justify="space-between">
      <v-col cols="auto">
        <v-btn class="mr-1" color="success" @click="saveQ()" v-if="!newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Update</v-btn
        >
        <v-btn class="mr-1" color="success" @click="saveAndAddQ()" v-if="newquestion">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Save & Add</v-btn
        >
        <v-btn class="mr-1" color="error" v-if="!newquestion" @click="deleteQ()">
          <font-awesome-icon icon="times-circle" pull="left"></font-awesome-icon>
          Delete</v-btn
        >
        <v-btn class="mr-1" color="warning" v-if="newquestion" @click="resetQ()">
          <font-awesome-icon icon="undo" pull="left"></font-awesome-icon>
          Reset</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn class="mr-1" color="secondary" @click="previousQ()" :disabled="firstquestion">
          <font-awesome-icon icon="angle-double-left" pull="left"></font-awesome-icon>
          Prev</v-btn
        >
        <v-btn class="mr-1" color="secondary" @click="next()" :disabled="newquestion || lastquestion">
          <font-awesome-icon icon="angle-double-right" pull="left"></font-awesome-icon>
          Next</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn color="accent" @click="addQ()" v-if="!newquestion">
          <font-awesome-icon icon="plus" pull="left"></font-awesome-icon>
          Add New
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "QuestionFormToolbar",
  props: {
    questionid: {
      default: () => null,
      type: Number,
    },
    questiontotal: {
      default: () => null,
      type: Number,
    },
  },
  computed: {
    newquestion() {
      return this.questionid === null
    },
    firstquestion() {
      return this.questionid === 0
    },
    lastquestion() {
      return this.questionid + 1 === this.questiontotal
    },
  },
  methods: {
    addQ() {
      this.$emit("addQuestion")
    },
    saveQ() {
      this.$emit("saveQuestion")
    },
    saveAndAddQ() {
      this.$emit("saveAndAddNewQuestion")
    },
    deleteQ() {
      this.$emit("deleteQuestion")
    },
    resetQ() {
      this.$emit("resetQuestion")
    },
    next() {
      this.$emit("nextQuestion")
    },
    previousQ() {
      this.$emit("previousQuestion")
    },
    settings() {
      this.$emit("openSettings")
    },
  },
}
</script>

<style lang="scss" scoped></style>
