<template>
  <v-hover v-slot="{ hover }">
    <v-alert
      class="questioncard ma-3 secondary--text"
      :class="{ active: active, jiggle: active }"
      @click="setQuestion(qid)"
      v-if="question"
      border="left"
      colored-border
      :color="active || hover ? 'accent' : 'accent2'"
      :elevation="hover ? 2 : 0"
    >
      <!-- :elevation="active ? 24 : 12" -->
      <v-row no-gutters class="d-flex align-start">
        <v-col cols="2" v-if="!compact">
          <p class="accent--text text--lighten-2 font-weight-black text-h4">{{ qid + 1 }}</p>
        </v-col>
        <v-col cols="10" v-if="!compact">
          <v-chip color="transparent" class="primary--text subtitle-2">
            <font-awesome-icon icon="star" pull="left"></font-awesome-icon>
            {{ question.points }} p</v-chip
          >
          <v-chip color="transparent" class="primary--text subtitle-2">
            <font-awesome-icon icon="clock" pull="left"></font-awesome-icon>
            {{ question.timer }}s</v-chip
          >
          <v-chip color="transparent" class="primary--text" v-if="question.imagelink">
            <font-awesome-icon icon="image" pull="left"></font-awesome-icon>
          </v-chip>
          <v-chip color="transparent" class="primary--text" v-if="question.answertype === 'P'">
            <font-awesome-icon icon="poll" pull="left"></font-awesome-icon>
          </v-chip>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <span class="font-weight-bold mr-3 text-truncate text-h6" v-show="compact">{{ qid + 1 }}</span>
          <span class="text-truncate subtitle-1" style="max-width: clamp(15vw, 20vw, 25vw)">
            {{ question.question }}
          </span>
        </v-col>

        <slot name="answerslot" v-if="!compact"></slot>
      </v-row>
    </v-alert>
  </v-hover>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "question-card",
  props: {
    question: {
      default: () => {},
      type: Object,
    },
    qid: {
      default: () => null,
      type: Number,
    },
    active: {
      default: () => false,
      type: Boolean,
    },
    compact: {
      default: () => true,
      type: Boolean,
    },
  },
  // mounted() {
  //   this.$eventBus.$on("selectInQuestionSideBar", this.setQuestion)
  // },
  methods: {
    ...mapActions(["setCurrentQuestion"]),
    setQuestion(q) {
      this.setCurrentQuestion(q)
    },
  },
}
</script>
<style lang="scss" scoped>
.linevisibility_sm {
  display: inline-block;
  width: 90vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  line-height: 1;
  // word-wrap: normal;
}
.linevisibility {
  display: inline-block;
  width: 20vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  line-height: 1;
  // word-wrap: normal;
}
.questioncard {
  border: 1px solid var(--v-accent2-base);
  transition: all 0.3s ease-in-out;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &.active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    border: 1px solid var(--v-accent-base);
  }
  &:hover {
    border: 1px solid var(--v-accent-base);
  }
  &.jiggle {
    animation: jiggle 0.6s ease infinite;
    animation-duration: 0.6s;
    animation-name: jiggle;
    transform: rotate(-0.5deg);
  }
}

@keyframes jiggle {
  0% {
    transform: rotate(-0.5deg);
  }
  50% {
    transform: rotate(0.5deg);
  }
}
</style>
