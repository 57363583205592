<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <v-chip
        label
        small
        v-for="(answer, ansindex) in answers"
        class="font-weight-medium subtitle-2 disable-events mr-1 my-1"
        color="primary"
        :text="!correct[ansindex]"
        :key="ansindex"
        :outlined="!correct[ansindex]"
      >
        {{ answer }}
        <font-awesome-icon v-if="correct[ansindex]" icon="check" pull="right"></font-awesome-icon>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuestionCardAnswersList",
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
    correct: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.disable-events {
  pointer-events: none;
}
</style>
