<template>
  <v-form v-model="valid" ref="newQuestionForm" lazy-validation @submit.prevent="saveQuestion()">
    <v-card
      class="d-flex flex-column pa-4 rounded-lg questionform"
      :outlined="$vuetify.breakpoint.mdAndUp"
      :elevation="$vuetify.breakpoint.mdAndUp ? 0 : 0"
      min-height="80vh"
      :class="!valid ? 'invalid' : ''"
    >
      <v-card-title class="text-h5 font-weight-black">{{
        currentQuestionId !== null ? `Editing Question ${currentQuestionId + 1}` : "New Question"
      }}</v-card-title>
      <v-row align="start" justify="space-between">
        <v-col cols="12" md="6" :order="$vuetify.breakpoint.smAndDown ? 1 : 0">
          <v-col cols="12" class="pa-4 mb-2">
            <v-textarea
              dense
              rows="1"
              label="Question"
              placeholder="Enter your question here"
              :value="currentquestion.question"
              @blur="updateCurrentQuestion({ question: $event.target.value })"
              :rules="questionrule"
              counter
              clearable
              maxlength="150"
              class="mb-2 pa-0"
            >
            </v-textarea>

            <v-textarea
              dense
              v-if="hintenabled && currentquestion.answertype !== 'P'"
              rows="1"
              label="Hint"
              placeholder="Participant can see this during gameplay optionally."
              :value="currentquestion.hint"
              @blur="updateCurrentQuestion({ hint: $event.target.value })"
              :rules="hintrule"
              counter
              clearable
              maxlength="150"
              class="mx-0 mt-4 pa-0"
            >
            </v-textarea>
          </v-col>
          <v-row
            v-for="(ans, ansindex) in currentquestion.answers"
            :key="ansindex"
            no-gutters
            class="px-2 py-1 mb-xl-2"
          >
            <v-checkbox
              dense
              v-if="currentquestion.answertype !== 'P'"
              :input-value="currentquestion.correctanswers[ansindex]"
              value
              @click="handleCorrectAnswer(currentquestion.correctanswers[ansindex], ansindex)"
            ></v-checkbox>
            <v-text-field
              dense
              :label="`Answer ${Number(ansindex) + 1}`"
              :value="ans"
              @blur="handleAnswerText($event.target.value, ansindex)"
              :rules="answerrule"
            >
              <template v-slot:append v-if="currentquestion.answertype !== 'T'">
                <v-btn
                  icon
                  color="success"
                  text
                  x-small
                  v-if="ansindex === currentquestion.answers.length - 1 && ansindex < 5"
                >
                  <font-awesome-icon icon="plus" @click="addOption"></font-awesome-icon>
                </v-btn>
                <v-btn icon color="error" text x-small v-if="ansindex > 1">
                  <font-awesome-icon color="error" icon="minus" @click="removeOption(ansindex)"></font-awesome-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters align="center" justify="center" class="background mb-1 rounded-lg">
            <v-radio-group
              :value="currentquestion.answertype"
              @blur="updateCurrentQuestion({ question: $event.target.value })"
              mandatory
              row
            >
              <v-radio dense value="T" @click="setOptions('T')" label="True/False"> </v-radio>
              <v-radio dense value="M" @click="setOptions('M')" label="Multi-Choice"> </v-radio>
              <v-radio dense value="P" @click="setOptions('P')" label="Poll"> </v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-col cols="12" class="background mb-1 rounded-lg">
              <v-slider
                hide-details
                label="Points"
                :value="currentquestion.points"
                @end="updateCurrentQuestion({ points: $event })"
                step="10"
                min="10"
                max="100"
                thumb-label="always"
                class="px-4 py-2"
              >
              </v-slider>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" class="background mb-1 rounded-lg">
              <v-slider
                hide-details
                label="Time"
                :value="currentquestion.timer"
                @end="updateCurrentQuestion({ timer: $event })"
                step="5"
                min="15"
                max="90"
                thumb-label="always"
                class="px-4 py-2"
              >
              </v-slider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <slot name="questionattachment"></slot>
          </v-row>
        </v-col>
      </v-row>
      <question-form-toolbar
        :questionid="currentQuestionId"
        :questiontotal="totalquestions"
        @nextQuestion="next"
        @previousQuestion="previous"
        @addQuestion="setQuestionFromQuiz"
        @resetQuestion="resetQuestion"
        @openSettings="openSettings"
        @deleteQuestion="deleteQuestion"
        @saveQuestion="saveQuestion"
        @saveAndAddNewQuestion="saveAndAddNewQuestion"
      ></question-form-toolbar>
    </v-card>
  </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import QuestionFormToolbar from "@/components/quiz/QuestionFormToolbar.vue"

export default {
  name: "question-form",
  props: {
    currentQuestionId: {
      default: () => null,
      type: Number,
    },
    totalquestions: {
      default: () => 0,
      type: Number,
    },
  },
  components: {
    QuestionFormToolbar,
  },
  data() {
    return {
      valid: true,
      truefalse: ["True", "False"],
      basemcq: ["", ""],
      basecorrect: [false, false],
      hintrule: [(v) => !!v || (!v && this.currentquestion.answertype !== "P" ? "Hint is Required" : true)],
      questionrule: [(v) => !!v || "Question title is required!"],
      pointsrule: [(v) => !!v || "Points are required!"],
      answerrule: [
        (v) => !!v || "Answer is required!",
        () => {
          if (this.currentquestion.answertype !== "P") {
            return (
              this.currentquestion.correctanswers.filter((item) => item).length > 0 ||
              "Atleast 1 answer must be marked correct"
            )
          } else {
            return true
          }
        },
      ],
      timer: [(v) => !!v || "Timer is required"],
    }
  },

  computed: {
    ...mapState({
      currentQuiz: (state) => state.quiz.currentQuiz,
      currentquestion: (state) => state.quiz.currentQuestion,
    }),
    hintenabled() {
      // if (this.currentQuiz && this.currentQuiz.lifelines) {
      return this.currentQuiz?.lifelines?.includes("hint") ?? false
      // } else {
      // return false
      // }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setQuestionFromQuiz()
    })
  },

  destroyed() {
    this.clearCurrentQuestion()
  },

  watch: {
    currentQuestionId: {
      handler(n) {
        // console.log(n)
        if (n === undefined || n === null) this.setQuestionFromQuiz()
      },
    },
  },

  methods: {
    ...mapActions([
      "updateCurrentQuestion",
      "addOrUpdateQuestionInQuiz",
      "deleteQuestionInQuiz",
      "clearCurrentQuestion",
      "setCurrentQuestion",
      "setCurrentQuestionFromQuiz",
    ]),
    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
    }),

    addOption() {
      this.updateCurrentQuestion({
        answers: [...this.currentquestion.answers, ""],
        correctanswers: [...this.currentquestion.correctanswers, false],
      })
    },

    removeOption(q) {
      var answerArray = [...this.currentquestion.answers]
      var correctAnswerArray = [...this.currentquestion.correctanswers]
      answerArray.splice(q, 1)
      correctAnswerArray.splice(q, 1)
      this.updateCurrentQuestion({ answers: [...answerArray], correctanswers: [...correctAnswerArray] })
    },

    setOptions(n) {
      var newArr = n === "T" ? [...this.truefalse] : n === "M" ? [...this.basemcq] : [...this.basemcq]
      this.updateCurrentQuestion({
        answers: [...newArr],
        correctanswers: [...this.basecorrect],
        answertype: n,
      })
    },

    handleCorrectAnswer(ans, i) {
      var newArr = [...this.currentquestion.correctanswers]
      newArr[i] = !ans
      this.updateCurrentQuestion({ correctanswers: [...newArr] })
    },

    handleAnswerText(ans, i) {
      var newArr = [...this.currentquestion.answers]
      newArr[i] = ans
      this.updateCurrentQuestion({ answers: [...newArr] })
    },

    async saveQuestion() {
      if (this.$refs.newQuestionForm.validate()) {
        try {
          await this.addOrUpdateQuestionInQuiz()
        } catch (err) {
          this.showAlert(`Problem Saving ${err.message}`)
        }
      }
    },

    async saveAndAddNewQuestion() {
      if (this.$refs.newQuestionForm.validate()) {
        this.saveQuestion().then(() => {
          this.setQuestionFromQuiz()
        })
      }
    },

    deleteQuestion() {
      this.deleteQuestionInQuiz().then(() => this.setCurrentQuestionFromQuiz())
    },

    resetQuestion() {
      if (this.currentQuestionId && this.currentquestion.imagelink) {
        this.$eventBus.$emit("deleteImage")
      }
      this.$refs.newQuestionForm.reset()
    },

    //if conditions here as we are not showing the question list in small screens
    next() {
      let q = this.currentQuestionId + 1
      this.setCurrentQuestion(q)
    },

    previous() {
      let q = !this.currentQuestionId ? this.totalquestions - 1 : this.currentQuestionId - 1
      this.setCurrentQuestion(q)
    },

    openSettings() {
      this.$emit("closeQuestionForm")
    },

    goBack() {
      this.$router.push("/dashboard")
    },

    async setQuestionFromQuiz() {
      const quiz = await this.$store.getters.getQuizById(this.$route.params.id)
      // const quiz = await this.currentQuiz
      for (let i = 2; i < quiz.choices; i++) {
        this.basemcq = new Array(quiz.choices).fill("")
        this.basecorrect = new Array(quiz.choices).fill(false)
      }
      const question = {
        question: "",
        hint: "",
        timer: quiz.timer,
        points: quiz.points,
        answertype: quiz.defaultquestiontype,
        imagelink: "",
        fileName: "",
        score: 0,
        selectedanswer: "",
        chosenanswer: "No Answer",
        elapsedtime: "",
        correctanswers: this.basecorrect,
        answers: this.basemcq,
      }
      this.setCurrentQuestionFromQuiz(question)
    },
  },
}
</script>

<style lang="scss" scoped>
.invalid {
  border-color: var(--v-error-base) !important;
}
.questionform {
  border: 1px solid var(--v-accent2-base);
  color: var(--v-primary-base);
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  transform: translateX(-10px);
  opacity: 1;
}
</style>
