var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.$vuetify.breakpoint.xs
        ? _c(
            "v-row",
            {
              staticClass: "pt-2",
              attrs: {
                align: "center",
                justify: "space-between",
                "no-gutters": ""
              }
            },
            [
              !_vm.newquestion
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", large: "", color: "success" },
                          on: {
                            "~click": function($event) {
                              return _vm.saveQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", size: "lg" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newquestion
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", large: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveAndAddQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", size: "lg" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.newquestion
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", large: "", color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.deleteQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times-circle", size: "lg" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newquestion
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", large: "", color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.resetQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "undo", size: "lg" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        color: "secondary",
                        disabled: _vm.firstquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.previousQ()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-left", size: "lg" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        large: "",
                        color: "secondary",
                        disabled: _vm.newquestion || _vm.lastquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-right", size: "lg" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", large: "", color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.addQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "plus", size: "lg" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", large: "", color: "accent" },
                      on: {
                        click: function($event) {
                          return _vm.settings()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "cog", size: "lg" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.sm
        ? _c(
            "v-row",
            { attrs: { justify: "space-between" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Update")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveAndAddQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Save & Add")
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.deleteQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times-circle", pull: "left" }
                          }),
                          _vm._v(" Delete")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.resetQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "undo", pull: "left" }
                          }),
                          _vm._v(" Reset")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        icon: "",
                        large: "",
                        color: "secondary",
                        disabled: _vm.firstquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.previousQ()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-left", size: "lg" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        icon: "",
                        large: "",
                        color: "secondary",
                        disabled: _vm.newquestion || _vm.lastquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-right", size: "lg" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.addQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "plus", pull: "left" }
                          }),
                          _vm._v(" Add New ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { large: "", color: "accent" },
                      on: {
                        click: function($event) {
                          return _vm.settings()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "cog", pull: "left" }
                      }),
                      _vm._v(" Settings ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.md
        ? _c(
            "v-row",
            { attrs: { justify: "space-between" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Update")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveAndAddQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Save & Add")
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.deleteQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times-circle", pull: "left" }
                          }),
                          _vm._v(" Delete")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.resetQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "undo", pull: "left" }
                          }),
                          _vm._v(" Reset")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.firstquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.previousQ()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-left", pull: "left" }
                      }),
                      _vm._v(" Prev")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.newquestion || _vm.lastquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-right", pull: "left" }
                      }),
                      _vm._v(" Next")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "accent", disabled: _vm.newquestion },
                      on: {
                        click: function($event) {
                          return _vm.addQ()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "plus", pull: "left" }
                      }),
                      _vm._v(" Add New ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-row",
            { attrs: { justify: "space-between" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Update")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.saveAndAddQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Save & Add")
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.deleteQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times-circle", pull: "left" }
                          }),
                          _vm._v(" Delete")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.resetQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "undo", pull: "left" }
                          }),
                          _vm._v(" Reset")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.firstquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.previousQ()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-left", pull: "left" }
                      }),
                      _vm._v(" Prev")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.newquestion || _vm.lastquestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-double-right", pull: "left" }
                      }),
                      _vm._v(" Next")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  !_vm.newquestion
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.addQ()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "plus", pull: "left" }
                          }),
                          _vm._v(" Add New ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }