var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "background pa-4 rounded-lg", attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", "no-gutters": "" } },
        [
          !_vm.currentquestion.imagelink & !_vm.uploadEnd && !_vm.uploading
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", block: "", color: "success" },
                  on: { click: _vm.selectFile }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "upload", pull: "left" }
                  }),
                  _vm._v(" Upload Image")
                ],
                1
              )
            : _vm._e(),
          _vm.uploadEnd || _vm.currentquestion.imagelink
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", block: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.deleteImage()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "times", pull: "left" }
                  }),
                  _vm._v(" Delete Image")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pb-xl-4",
          attrs: { align: "center", justify: "center", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "text-center" },
            [
              _vm.uploadEnd || _vm.currentquestion.imagelink
                ? _c("v-img", {
                    staticClass: "attachment",
                    attrs: {
                      src: _vm.currentquestion.imagelink,
                      height: "150",
                      contain: ""
                    }
                  })
                : _vm._e(),
              _vm.uploading && !_vm.uploadEnd
                ? _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        size: 100,
                        width: 10,
                        rotate: 360,
                        value: _vm.progressUpload,
                        color: "warning"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.progressUpload) + " % ")]
                  )
                : _vm._e(),
              _c("input", {
                ref: "uploadInput",
                attrs: {
                  id: "files",
                  type: "file",
                  name: "file",
                  accept: "image/*",
                  multiple: false
                },
                on: {
                  change: function($event) {
                    return _vm.detectFiles($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }