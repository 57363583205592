var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var hover = ref.hover
            return [
              _vm.question
                ? _c(
                    "v-alert",
                    {
                      staticClass: "questioncard ma-3 secondary--text",
                      class: { active: _vm.active, jiggle: _vm.active },
                      attrs: {
                        border: "left",
                        "colored-border": "",
                        color: _vm.active || hover ? "accent" : "accent2",
                        elevation: hover ? 2 : 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.setQuestion(_vm.qid)
                        }
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-flex align-start",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          !_vm.compact
                            ? _c("v-col", { attrs: { cols: "2" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "accent--text text--lighten-2 font-weight-black text-h4"
                                  },
                                  [_vm._v(_vm._s(_vm.qid + 1))]
                                )
                              ])
                            : _vm._e(),
                          !_vm.compact
                            ? _c(
                                "v-col",
                                { attrs: { cols: "10" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "primary--text subtitle-2",
                                      attrs: { color: "transparent" }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "star", pull: "left" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.question.points) + " p"
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "primary--text subtitle-2",
                                      attrs: { color: "transparent" }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "clock", pull: "left" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.question.timer) + "s"
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.question.imagelink
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "primary--text",
                                          attrs: { color: "transparent" }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "image",
                                              pull: "left"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.question.answertype === "P"
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "primary--text",
                                          attrs: { color: "transparent" }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "poll",
                                              pull: "left"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-start align-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.compact,
                                      expression: "compact"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-bold mr-3 text-truncate text-h6"
                                },
                                [_vm._v(_vm._s(_vm.qid + 1))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-truncate subtitle-1",
                                  staticStyle: {
                                    "max-width": "clamp(15vw, 20vw, 25vw)"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.question.question) + " "
                                  )
                                ]
                              )
                            ]
                          ),
                          !_vm.compact ? _vm._t("answerslot") : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }