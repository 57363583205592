var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questions
    ? _c(
        "v-row",
        {
          staticClass: "sticky mb-4 accent3 secondary--text",
          attrs: { "no-gutters": "" }
        },
        [
          _vm._l(_vm.stats, function(stat, key) {
            return _c(
              "v-col",
              {
                key: stat.key,
                staticClass: "pa-sm-4 d-flex flex-column align-center",
                attrs: { cols: "3" }
              },
              [
                _c(
                  "v-avatar",
                  { attrs: { color: "accent2", size: _vm.size } },
                  [
                    _c("span", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(_vm._s(stat))
                    ])
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "text-center subtitle-2 font-weight-bold" },
                  [_vm._v(_vm._s(key))]
                )
              ],
              1
            )
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-sm-4 d-flex flex-column align-center",
              attrs: { cols: "3" }
            },
            [
              _c(
                "v-avatar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.compact,
                      expression: "!compact"
                    }
                  ],
                  attrs: { color: "accent2", size: _vm.size }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "secondary" },
                      on: { click: _vm.docompact }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "ma-1",
                        attrs: { icon: "compress" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.compact
                ? _c(
                    "span",
                    { staticClass: "text-center font-weight-bold subtitle-2" },
                    [_vm._v("collapse")]
                  )
                : _vm._e(),
              _c(
                "v-avatar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.compact,
                      expression: "compact"
                    }
                  ],
                  attrs: { color: "accent2", size: _vm.size }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "secondary" },
                      on: { click: _vm.docompact }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "ma-1",
                        attrs: { icon: "expand" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.compact
                ? _c(
                    "span",
                    { staticClass: "text-center font-weight-bold subtitle-2" },
                    [_vm._v("expand")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }