var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quiz
    ? _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c("QuizFormToolBar", {
            attrs: { editquiz: _vm.editquiz },
            on: { togglequizform: _vm.toggleQuizForm }
          }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$vuetify.breakpoint.mdAndUp && !_vm.editquiz,
                      expression: "$vuetify.breakpoint.mdAndUp && !editquiz"
                    }
                  ],
                  staticClass: "question-list-column accent3 px-xl-2",
                  attrs: { cols: "12", md: "4", xl: "3" }
                },
                [
                  _c("QuizStatsCircle", {
                    attrs: {
                      questions: _vm.quiz.questions,
                      compact: _vm.compact
                    },
                    on: { docompact: _vm.docompact }
                  }),
                  _vm.questions && _vm.questions.length === 0
                    ? _c("h3", { staticClass: "subtitle-1 ma-4 text-center" }, [
                        _vm._v(
                          " Questions you add will appear here. Add a minimum of 5 Questions to start the quiz and play "
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "draggable",
                    {
                      on: {
                        start: function($event) {
                          _vm.drag = true
                        },
                        end: function($event) {
                          _vm.drag = false
                        }
                      },
                      model: {
                        value: _vm.questions,
                        callback: function($$v) {
                          _vm.questions = $$v
                        },
                        expression: "questions"
                      }
                    },
                    [
                      _c(
                        "transition-group",
                        {
                          attrs: {
                            name: "flip-list",
                            tag: "div",
                            "enter-active-class":
                              "animate__animated animate__slideInLeft",
                            "leave-active-class":
                              "animate__animated animate__slideOutLeft"
                          }
                        },
                        _vm._l(_vm.questions, function(question, index) {
                          return _c("question-card", {
                            key: question.question + index,
                            attrs: {
                              question: question,
                              qid: index,
                              active: _vm.currentQuestionId === index,
                              compact: _vm.compact
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "answerslot",
                                  fn: function() {
                                    return [
                                      _c("question-card-answers-list", {
                                        attrs: {
                                          answers: question.answers,
                                          correct: question.correctanswers
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "white main-column",
                  attrs: {
                    cols: "12",
                    md: _vm.editquiz ? 12 : 8,
                    xl: _vm.editquiz ? 12 : 9
                  }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-4 pa-lg-4" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                "enter-active-class":
                                  "animate__animated animate__slideInDown",
                                "leave-active-class":
                                  "animate__animated animate__slideOutRight",
                                mode: "out-in"
                              }
                            },
                            [
                              _vm.editquiz
                                ? _c("quiz-form", {
                                    key: _vm.editquiz
                                      ? "questionform"
                                      : "quizform",
                                    attrs: {
                                      quiz: _vm.quiz,
                                      quizid: _vm.quizid
                                    },
                                    on: { closeQuizForm: _vm.toggleQuizForm }
                                  })
                                : _vm._e(),
                              !_vm.editquiz
                                ? _c("question-form", {
                                    key: _vm.editquiz
                                      ? "questionform"
                                      : "quizform",
                                    attrs: {
                                      currentQuestionId: _vm.currentQuestionId,
                                      totalquestions: _vm.questions.length
                                    },
                                    on: {
                                      showQuestionForm: _vm.toggleQuizForm,
                                      closeQuestionForm: _vm.toggleQuizForm
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "questionattachment",
                                          fn: function() {
                                            return [_c("question-attachment")]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1715926085
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("Loading", {
            attrs: { loading: _vm.overlay, text: _vm.quizLoadingText }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }